import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import { DateUtils } from "@/utils/DateUtils";

export class AppointmentGraphQLService implements IAppointmentService {
  public static get appointmentFields() {
    return [
      "id",
      "type",
      "date",
      "addressStreet",
      "addressZip",
      "addressCity",
      "addressState",
      "addressCountry",
      "addressLng",
      "addressLat",
      "reason",
      "duration",
      "tourWeekId",
      "tourDayIndex",
      "plannedDate",
      "remarks",
      "plantAddress",
      "operatorPhoneNumber",
      "plantType",
      "operatorName",
      "operatorAddress",
      "requestedBy",
      {
        name: "plant",
        fields: [
          "id",
          "index",
          "group",
          "type",
          "remarks",
          {
            name: "operators",
            fields: ["id", "name"]
          }
        ]
      }
    ];
  }

  public constructor(private connection: GraphQLConnection) {}

  public async getAppointmentById(appointmentId: string): Promise<Appointment> {
    const result = await this.connection.query(
      "getAppointmentById",
      {
        appointmentId
      },
      AppointmentGraphQLService.appointmentFields
    );

    return this.parseRawAppointment(result.data);
  }

  public async getFilteredOpenAppointments(
    startDate: string,
    endDate: string,
    types: string[],
    states: string[]
  ): Promise<any[]> {
    const result = await this.connection.query(
      "getFilteredOpenAppointments",
      {
        startDate,
        endDate,
        types,
        states
      },
      [
        "id",
        "type",
        "date",
        "addressLng",
        "addressLat",
        { name: "plant", fields: ["index"] }
      ]
    );

    return result.data.map((item: any) => this.parseRawAppointment(item));
  }

  public async getAppointmentsForMap(
    startDate: string,
    endDate: string,
    types: string[],
    states: string[]
  ): Promise<any[]> {
    const result = await this.connection.query(
      "getAppointmentsForMap",
      {
        startDate,
        endDate,
        types,
        states
      },
      [
        "id",
        { name: "position", fields: ["lat", "lng"] },
        "icon",
        {
          name: "infoWindowContent",
          fields: [
            "plantIndex",
            "plantGroup",
            "plantType",
            "plantRemarks",
            "operators",
            "address",
            "date",
            "type",
            "reason"
          ]
        }
      ]
    );

    return result.data;
  }

  public async getAppointmentsByPlantId(plantId: string): Promise<any[]> {
    const result = await this.connection.query(
      "getAppointmentsByPlantId",
      {
        plantId
      },
      [
        "id",
        "type",
        "date",
        "processingState",
        "addressStreet",
        "addressZip",
        "addressCity",
        "addressState",
        "addressCountry",
        "addressLng",
        "addressLat",
        "reason",
        "duration",
        "plannedDate",
        "cancellationReason"
      ]
    );

    return result.data.map((item: any) => this.parseRawAppointment(item));
  }

  public async getAllSelectedAppointmentsByTourPlanner(
    tourPlannerId: string
  ): Promise<Appointment[]> {
    const result = await this.connection.query(
      "getAllSelectedAppointmentsByTourPlanner",
      {
        tourPlannerId
      },
      AppointmentGraphQLService.appointmentFields
    );

    return result.data.map((item: any) => this.parseRawAppointment(item));
  }

  public async getAllAtLeastPlannedAppointmentsByTourPlanner(
    tourPlannerId: string
  ): Promise<Appointment[]> {
    const result = await this.connection.query(
      "getAllAtLeastPlannedAppointmentsByTourPlanner",
      {
        tourPlannerId
      },
      AppointmentGraphQLService.appointmentFields
    );

    return result.data.map((item: any) => this.parseRawAppointment(item));
  }

  public async createLooseAppointment(appointmentProps: any): Promise<string> {
    const result = await this.connection.mutation(
      "createLooseAppointment",
      {
        input: { type: "LOOSE", ...appointmentProps }
      },
      ["error", "stack"]
    );

    return result.data.id;
  }

  public async selectAppointment(
    tourPlannerId: string,
    appointmentId: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "selectAppointment",
      {
        input: {
          tourPlannerId,
          appointmentId
        }
      },
      ["error", "stack"]
    );

    return result.data.id;
  }

  public async unselectAppointment(appointmentId: string): Promise<string> {
    const result = await this.connection.mutation(
      "unselectAppointment",
      {
        input: {
          appointmentId
        }
      },
      []
    );

    return result.data;
  }

  public async updateAppointmentTourDayIndex(
    appointmentId: string,
    tourWeekId: string | null,
    tourDayIndex: number | null,
    plannedDate: string | null
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updateAppointment",
      {
        input: {
          appointmentId,
          tourDayIndex,
          tourWeekId,
          plannedDate,
          processingState: tourDayIndex !== null ? "PLANNED" : "SELECTED"
        }
      },
      []
    );

    return result.data.id;
  }

  public async updateAppointmentReasonDuration(
    appointmentId: string,
    reason: string,
    duration: string,
    remarks: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "updateAppointment",
      {
        input: {
          appointmentId,
          reason,
          duration,
          remarks
        }
      },
      []
    );

    return result.data;
  }

  public async createAppointmentCoordinates(
    appointmentId: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "createAppointmentCoordinates",
      {
        input: {
          appointmentId
        }
      },
      ["error", "stack"]
    );

    return result.data.id;
  }

  public async cancelAppointment(
    appointmentId: string,
    cancellationReason: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "cancelAppointment",
      {
        input: {
          appointmentId,
          cancellationReason
        }
      },
      []
    );

    return result.data;
  }

  private parseRawAppointment(appointmentRaw: any): Appointment {
    const parsedAppointment: Appointment = appointmentRaw;
    parsedAppointment.date = DateUtils.getDate(appointmentRaw.date);
    if (!!parsedAppointment.plannedDate) {
      parsedAppointment.plannedDate = new Date(
        parseInt(appointmentRaw.plannedDate, 10)
      );
    }
    parsedAppointment.addressCountry = CountriesStorageHandler.getCountryNameFromId(
      appointmentRaw.addressCountry
    );

    if (!!parsedAppointment.plant) {
      parsedAppointment.plant.group = PlantGroupStorageHandler.getGroupNameFromId(
        appointmentRaw.plant.group
      );
      parsedAppointment.plant.type = PlantGroupStorageHandler.getTypeNameFromId(
        appointmentRaw.plant.type
      );
    }

    return parsedAppointment;
  }
}

export interface Appointment {
  id: string;
  type: string;
  date: Date;
  addressStreet: string;
  addressZip: string;
  addressCity: string;
  addressCountry: string;
  addressState: string;
  addressLat: number;
  addressLng: number;
  plant: Plant | null;
  reason: string;
  duration: number;
  plannedDate: Date;
  loading?: boolean;
  updateSuccess?: boolean;
  customColor?: string;
  updateFunction: () => Promise<any>;
}

export interface Plant {
  id: string;
  group: string;
  type: string;
  operators: Operator[];
}

export interface Operator {
  id: string;
  name: string;
}

export interface IAppointmentService {
  getAppointmentById(appointmentId: string): Promise<Appointment>;
  createLooseAppointment(appointmentProps: any): Promise<string>;
  selectAppointment(
    tourPlannerId: string,
    appointmentId: string
  ): Promise<string>;
  unselectAppointment(appointmentId: string): Promise<string>;
  getAllSelectedAppointmentsByTourPlanner(
    tourPlannerId: string
  ): Promise<Appointment[]>;
  getAllAtLeastPlannedAppointmentsByTourPlanner(
    tourPlannerId: string
  ): Promise<Appointment[]>;
  getFilteredOpenAppointments(
    startDate: string,
    endDate: string,
    types: string[],
    states: string[]
  ): Promise<Appointment[]>;
  getAppointmentsForMap(
    startDate: string,
    endDate: string,
    types: string[],
    states: string[]
  ): Promise<any[]>;
  updateAppointmentTourDayIndex(
    appointmentId: string,
    tourWeekId: string | null,
    tourDayIndex: number | null,
    plannedDate: string | null
  ): Promise<string>;
  updateAppointmentReasonDuration(
    appointmentId: string,
    reason: string,
    duration: string,
    comment: string
  ): Promise<string>;
  createAppointmentCoordinates(appointmentId: string): Promise<string>;
  cancelAppointment(
    appointmentId: string,
    cancellationReason: string
  ): Promise<string>;
}
