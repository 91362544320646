import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CustomerServiceReportStorageHandler extends StorageHandler {
  public static getAllReasons(): SelectionItem[] {
    if (this.storage.has(SystemKeys.customerServiceReasonsTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.customerServiceReasonsTableKey)
      );

      return fields?.map((field: any) => ({
        text:
          field[SystemKeys.values][SystemKeys.customerServiceReasonsNameKey],
        value: {
          id: field[SystemKeys.id],
          additionalTextField:
            field[SystemKeys.values][
              SystemKeys.customerServiceReasonsAdditionalFieldKey
            ],
          additionalText: ""
        }
      }));
    }

    return [];
  }

  public static getAllPlantTypes(): SelectionItem[] {
    if (this.storage.has(SystemKeys.customerServicePlantTypeTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.customerServicePlantTypeTableKey)
      );

      return fields?.map((field: any) => ({
        text:
          field[SystemKeys.values][SystemKeys.customerServicePlantTypeNameKey],
        value:
          field[SystemKeys.values][SystemKeys.customerServicePlantTypeNameKey]
      }));
    }

    return [];
  }
}
