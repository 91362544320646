import { Request, Checkbox } from "@/forms/ViewModelFormTypes";

export class PlantViewModel {
  public plantRequest: Request = { loading: false, error: "" };
  public plant: any = {};
  public noSuchPlant: boolean = false;

  public location = { lat: 10, lng: 10 };

  public plantDataCheckbox = {
    label: "Anlagedaten",
    value: true,
    error: "",
    component: "PlantData",
    size: "xs12 md12"
  };
  public workOrdersCheckbox = {
    label: "Durchgeführte Wartungen",
    value: true,
    error: "",
    component: "PlantWorkOrdersData",
    size: "xs12 md12"
  };
  public protocolCheckbox = {
    label: "Protokoll",
    value: true,
    error: "",
    component: "PlantProtocolData",
    size: "xs12 md12"
  };
  public openDatesCheckbox = {
    label: "Offene Termine",
    value: true,
    error: "",
    component: "PlantOpenDatesData",
    size: "xs12 md12"
  };
  public contactPersonCheckbox = {
    label: "Ansprechpartner",
    value: true,
    error: "",
    component: "PlantContactPersonData",
    size: "xs12 md6"
  };
  public maintenanceContractCheckbox = {
    label: "Wartungsvertrag",
    value: true,
    error: "",
    component: "PlantMaintenanceContractData",
    size: "xs12 md6"
  };
  public remarksCheckbox = {
    label: "Anmerkungen",
    value: true,
    error: "",
    component: "PlantRemarksData",
    size: "xs12 md12"
  };
  public limitsCheckbox = {
    label: "Grenzwerte",
    value: true,
    error: "",
    component: "PlantLimitsData",
    size: "xs12 md12"
  };
  public installationPartsCheckbox = {
    label: "Einbauteile",
    value: true,
    error: "",
    component: "PlantInstallationPartsData",
    size: "xs12 md6"
  };
  public plantPartsCheckbox = {
    label: "Anlagenteile",
    value: true,
    error: "",
    component: "PlantPartsData",
    size: "xs12 md6"
  };
  public plantStoreItemsCheckbox = {
    label: "Verkaufte Artikel",
    value: true,
    error: "",
    component: "StoreItemCardView",
    size: "xs12"
  };
  public documentsCheckbox = {
    label: "Dokumente",
    value: true,
    error: "",
    component: "PlantDocumentsData",
    size: "xs12 md6"
  };
  public printTemplatesCheckbox = {
    label: "Druckvorlagen",
    value: true,
    error: "",
    component: "PlantPrintTemplatesData",
    size: "xs12 md6"
  };

  public filters: any[] = [
    this.plantDataCheckbox,
    this.workOrdersCheckbox,
    this.protocolCheckbox,
    this.openDatesCheckbox,
    this.contactPersonCheckbox,
    this.remarksCheckbox,
    this.limitsCheckbox,
    this.installationPartsCheckbox,
    this.plantPartsCheckbox,
    this.plantStoreItemsCheckbox,
    this.documentsCheckbox,
    this.printTemplatesCheckbox
  ];
}
