





























































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  AppointmentGraphQLService,
  Appointment
} from "../services/AppointmentGraphQLService";
import { FormRequestHandler } from "../../forms/FormRequestHandler";
import { Plant } from "@/plant/services/PlantService";
import { DateUtils } from "@/utils/DateUtils";
import { Auth } from "../../common/utils/Auth";
import StorageKeys from "../../common/utils/StorageKeys";
import { MathUtils } from "../../utils/MathUtils";
import { duration } from "moment";

@Component<PlantOpenDatesData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    DeleteDialog,
    HoverableDatatable,
    CreateLooseAppointmentView: () =>
      import("@/tourPlanner/views/CreateLooseAppointmentView.vue")
  },
  props: ["plant", "plantRequest", "element"],
  watch: {
    plant() {
      this.loadAppointments();
    },
    element(newValue: any) {
      if (newValue) {
        this.loadAppointments();
      }
    }
  }
})
export default class PlantOpenDatesData extends Vue {
  private plant!: Plant;
  private appointments: any[] = [];
  private components: any[] = [];
  private isDeleteDialogShown = false;
  private toDeleteAppointment: any = {};
  private cancellationReason: string = "";
  private appointmentHeaders = [
    { text: "Datum", value: "date", sortable: true, width: "20%" },
    { text: "Typ", value: "type", sortable: true },
    { text: "Dauer", value: "duration", sortable: true, width: "8%" },
    { text: "Termingrund", value: "reason" },
    { text: "Stornierungsgründe", value: "cancellationReason" },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "80px"
    }
  ];

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private appointmentService = new AppointmentGraphQLService(this.connection);

  private mounted() {
    if (!!this.plant.id) {
      this.loadAppointments();
    }
  }

  private addAppointmentButtonClicked() {
    this.components.push({
      component: "CreateLooseAppointmentView",
      plant: this.plant
    });
  }

  private retry() {
    this.components = [];
    this.$emit("retry");
  }

  private clearComponents() {
    this.components = [];
  }

  private getDate(item: any) {
    if (!!item.plannedDate) {
      return DateUtils.formatDate(item.plannedDate);
    }
    return DateUtils.formatDate(item.date);
  }

  private loadAppointments() {
    const request = this.appointmentService.getAppointmentsByPlantId(
      this.plant.id
    );
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          this.appointments = response.data;
        }
      },
      "get-appointments-failed"
    );
  }

  private parseType(type: string) {
    switch (type) {
      case "SPONTANEOUS":
        return "Termin";
      case "MAINTENANCE":
        return "Wartung";
      default:
        return "Termin";
    }
  }

  private parseDuration(durationInMinutes: number) {
    return (durationInMinutes / 60).toFixed(2) + " h";
  }

  private showDeleteDialog(appointment: any) {
    this.toDeleteAppointment = appointment;
    this.isDeleteDialogShown = true;
  }

  private deleteAppointmentButtonClicked(appointment: any) {
    const request = this.appointmentService.cancelAppointment(
      appointment.id,
      this.cancellationReason
    );
    FormRequestHandler.handle(
      request,
      response => {
        this.isDeleteDialogShown = false;
        if (!response.loading && !response.error) {
          this.toDeleteAppointment = {};
          this.cancellationReason = "";
          this.retry();
        }
      },
      "update-plant-failed"
    );
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
